import cn from "classnames"

import { ReactComponent as AiSvg } from "@/assets/icons/ai.svg"
import { ReactComponent as AKSSvg } from "@/assets/icons/aks.svg"
import { ReactComponent as AmazonSVG } from "@/assets/icons/amazon-logo.svg"
import { ReactComponent as ApkDarkSvg } from "@/assets/icons/apk-dark.svg"
import { ReactComponent as ApplicationExpandSvg } from "@/assets/icons/application-expanded.svg"
import { ReactComponent as ArrowUpSVG } from "@/assets/icons/arrow-up.svg"
import { ReactComponent as AsanaSVG } from "@/assets/icons/asana-logo.svg"
import { ReactComponent as AttackScenariosSvg } from "@/assets/icons/attack-scenarios.svg"
import { ReactComponent as AzureReposSVG } from "@/assets/icons/azure-repos-logo.svg"
import { ReactComponent as BaseImageSVG } from "@/assets/icons/base-image.svg"
import { ReactComponent as BinaryDarkSvg } from "@/assets/icons/binary-dark.svg"
import { ReactComponent as BitbucketDarkSvg } from "@/assets/icons/bitbucket-dark.svg"
import { ReactComponent as BitbucketSVG } from "@/assets/icons/bitbucket.svg"
import { ReactComponent as BugDemoSVG } from "@/assets/icons/bug-demo.svg"
import { ReactComponent as CDarkSvg } from "@/assets/icons/c-dark.svg"
import { ReactComponent as CSharpDarkSvg } from "@/assets/icons/c-sharp-dark.svg"
import { ReactComponent as CheckmarxSvg } from "@/assets/icons/checkmarx-logo.svg"
import { ReactComponent as CircleCISvg } from "@/assets/icons/circle-ci-logo.svg"
import { ReactComponent as CloudSyncSvg } from "@/assets/icons/cloud-sync.svg"
import { ReactComponent as CodeIssuesSvg } from "@/assets/icons/code-issues.svg"
import { ReactComponent as CodeRepoSvg } from "@/assets/icons/code-repo.svg"
import { ReactComponent as CodeWeaknessSvg } from "@/assets/icons/code-weakness.svg"
import { ReactComponent as ConnectEnvDataSvg } from "@/assets/icons/connect-env.svg"
import { ReactComponent as ConnectPlugSvg } from "@/assets/icons/connect-plug.svg"
import { ReactComponent as DartDarkSvg } from "@/assets/icons/dart-dark.svg"
import { ReactComponent as DebianDarkSVG } from "@/assets/icons/debian-dark.svg"
import { ReactComponent as DirectDependencySvg } from "@/assets/icons/direct-dependency.svg"
import { ReactComponent as DockerHubSvg } from "@/assets/icons/dockerhub-logo.svg"
import { ReactComponent as EasyFixCyanSVG } from "@/assets/icons/easy-fix-cyan.svg"
import { ReactComponent as EasyFixOrangeSVG } from "@/assets/icons/easy-fix-orange.svg"
import { ReactComponent as EasyFixSVG } from "@/assets/icons/easy-fix.svg"
import { ReactComponent as EC2Svg } from "@/assets/icons/ec2.svg"
import { ReactComponent as ECSSvg } from "@/assets/icons/ecs.svg"
import { ReactComponent as EKSSvg } from "@/assets/icons/eks.svg"
import { ReactComponent as EmptyLabelsSvg } from "@/assets/icons/empty-labels.svg"
import { ReactComponent as EnterKeySvg } from "@/assets/icons/enter-key.svg"
import { ReactComponent as EnterpriseSVG } from "@/assets/icons/enterprise.svg"
import { ReactComponent as ExploitPOCSvg } from "@/assets/icons/exploite-poc.svg"
import { ReactComponent as ExploiteSvg } from "@/assets/icons/exploite.svg"
import { ReactComponent as ExposedSecretSvg } from "@/assets/icons/exposed-secret.svg"
import { ReactComponent as ExternalLinkOutlinedSvg } from "@/assets/icons/external-link-outlined.svg"
import { ReactComponent as FargateSvg } from "@/assets/icons/fargate.svg"
import { ReactComponent as FixSvg } from "@/assets/icons/fix.svg"
import { ReactComponent as FlatListSvg } from "@/assets/icons/flat-list.svg"
import { ReactComponent as GcrSVG } from "@/assets/icons/gcr.svg"
import { ReactComponent as GithubActionsSvg } from "@/assets/icons/github-actions-logo.svg"
import { ReactComponent as GitHubSVG } from "@/assets/icons/github.svg"
import { ReactComponent as GitlabDarkSvg } from "@/assets/icons/gitlab-dark.svg"
import { ReactComponent as GitlabSvg } from "@/assets/icons/gitlab-logo.svg"
import { ReactComponent as GKESvg } from "@/assets/icons/gke.svg"
import { ReactComponent as GoDarkSVG } from "@/assets/icons/go-dark.svg"
import { ReactComponent as HasFixSvg } from "@/assets/icons/has-fix.svg"
import { ReactComponent as HierarchyLineIconSvg } from "@/assets/icons/hierarchy-line.svg"
import { ReactComponent as HierarchySvg } from "@/assets/icons/hierarchy.svg"
import { ReactComponent as ImageDemoSVG } from "@/assets/icons/image-demo.svg"
import { ReactComponent as ImageSVG } from "@/assets/icons/image.svg"
import { ReactComponent as IndirectDependencySvg } from "@/assets/icons/indirect-dependency.svg"
import { ReactComponent as IngressSvg } from "@/assets/icons/ingress.svg"
import { ReactComponent as InjectionSvg } from "@/assets/icons/injection.svg"
import { ReactComponent as InternalAppSvg } from "@/assets/icons/internal-app.svg"
import { ReactComponent as InternetFacingSvg } from "@/assets/icons/internet-facing.svg"
import { ReactComponent as JavaDarkSVG } from "@/assets/icons/java-dark.svg"
import { ReactComponent as JavascriptDarkSVG } from "@/assets/icons/javascript-dark.svg"
import { ReactComponent as JenkinsSvg } from "@/assets/icons/jenkins-logo.svg"
import { ReactComponent as JfrogSvg } from "@/assets/icons/jfrog-logo.svg"
import { ReactComponent as JiraLogoSVG } from "@/assets/icons/jira-logo.svg"
import { ReactComponent as JiraSoftwareLogoOutlinedSVG } from "@/assets/icons/jira-software-logo-outlined.svg"
import { ReactComponent as JiraSoftwareLogoSVG } from "@/assets/icons/jira-software-logo.svg"
import { ReactComponent as KeyIconSvg } from "@/assets/icons/key-icon.svg"
import { ReactComponent as KodemCISvg } from "@/assets/icons/kodem-ci-logo.svg"
import { ReactComponent as KodemLogoSvg } from "@/assets/icons/kodem-logo.svg"
import { ReactComponent as KotlinDarkSvg } from "@/assets/icons/kotlin-dark.svg"
import { ReactComponent as KubernetesSvg } from "@/assets/icons/kubernetes.svg"
import { ReactComponent as LinkExpiredSvg } from "@/assets/icons/link-expired.svg"
import { ReactComponent as LuaDarkSvg } from "@/assets/icons/lua-dark.svg"
import { ReactComponent as MalSvg } from "@/assets/icons/mal.svg"
import { ReactComponent as MendSvg } from "@/assets/icons/mend-logo.svg"
import { ReactComponent as NetDarkSvg } from "@/assets/icons/net-dark.svg"
import { ReactComponent as NoFoundSvg } from "@/assets/icons/no-found.svg"
import { ReactComponent as NoScopeSvg } from "@/assets/icons/no-scope.svg"
import { ReactComponent as OnPremImageSvg } from "@/assets/icons/on-prem-image.svg"
import { ReactComponent as OnPremRepoSvg } from "@/assets/icons/on-prem-repo.svg"
import { ReactComponent as OpenSourceIssueSvg } from "@/assets/icons/open-source-issue.svg"
import { ReactComponent as OrcaSvg } from "@/assets/icons/orca-logo.svg"
import { ReactComponent as PackageDemoSVG } from "@/assets/icons/package-demo.svg"
import { ReactComponent as PackageSvg } from "@/assets/icons/package.svg"
import { ReactComponent as PhpDarkSVG } from "@/assets/icons/php-dark.svg"
import { ReactComponent as PrismaCloudSvg } from "@/assets/icons/prismaCloud-logo.svg"
import { ReactComponent as PublicImageSVG } from "@/assets/icons/public-image.svg"
import { ReactComponent as PythonDarkSVG } from "@/assets/icons/python-dark.svg"
import { ReactComponent as QualysSvg } from "@/assets/icons/qualys-logo.svg"
import { ReactComponent as RDarkSvg } from "@/assets/icons/r-dark.svg"
import { ReactComponent as RemediatorSVG } from "@/assets/icons/remediator.svg"
import { ReactComponent as RiskPercentageSvg } from "@/assets/icons/risk-percentage.svg"
import { ReactComponent as RpmDarkSVG } from "@/assets/icons/rpm-dark.svg"
import { ReactComponent as RubyDarkSVG } from "@/assets/icons/ruby-dark.svg"
import { ReactComponent as RuntimeSvg } from "@/assets/icons/runtime.svg"
import { ReactComponent as RustDarkSVG } from "@/assets/icons/rust-dark.svg"
import { ReactComponent as ScalaDarkSVG } from "@/assets/icons/scala-dark.svg"
import { ReactComponent as ServiceNowSvg } from "@/assets/icons/service-now-logo.svg"
import { ReactComponent as SevereIssueSvg } from "@/assets/icons/severe-issue.svg"
import { ReactComponent as SlackSVG } from "@/assets/icons/slack-logo.svg"
import { ReactComponent as SnykSvg } from "@/assets/icons/snyk-logo.svg"
import { ReactComponent as SpaceKeySvg } from "@/assets/icons/space-key.svg"
import { ReactComponent as SplunkSvg } from "@/assets/icons/splunk-logo.svg"
import { ReactComponent as StoryJiraSvg } from "@/assets/icons/story-jira.svg"
import { ReactComponent as SwiftDarkSVG } from "@/assets/icons/swift-dark.svg"
import { ReactComponent as TableSvg } from "@/assets/icons/table.svg"
import { ReactComponent as TagMoreSvg } from "@/assets/icons/tag-more.svg"
import { ReactComponent as TrashOutlinedSvg } from "@/assets/icons/trash-outlined.svg"
import { ReactComponent as TypeScriptDarkSVG } from "@/assets/icons/typescript-dark.svg"
import { ReactComponent as UltimateFixSVG } from "@/assets/icons/ultimate-fix.svg"
import { ReactComponent as UnknownLanguageSvg } from "@/assets/icons/unknown-language-dark.svg"
import { ReactComponent as VMSvg } from "@/assets/icons/vm.svg"
import { ReactComponent as VSCodeSVG } from "@/assets/icons/vscode.svg"
import { ReactComponent as WebhookSvg } from "@/assets/icons/webhook.svg"
import { ReactComponent as WizSvg } from "@/assets/icons/wiz-logo.svg"
import { ReactComponent as ZombieSvg } from "@/assets/icons/zombie.svg"

import { default as Component } from "@ant-design/icons"

import styles from "./Icon.module.scss"

import type { IconProps } from "."

export const Enterprise = (props: IconProps) => (
  <Component component={EnterpriseSVG} {...props} />
)
export const CodeRepo = (props: IconProps) => (
  <Component component={CodeRepoSvg} {...props} />
)
export const BaseImage = (props: IconProps) => (
  <Component component={BaseImageSVG} {...props} />
)
export const DirectDependency = (props: IconProps) => (
  <Component component={DirectDependencySvg} {...props} />
)
export const IndirectDependency = (props: IconProps) => (
  <Component component={IndirectDependencySvg} {...props} />
)
export const RiskPercentage = (props: IconProps) => (
  <Component component={RiskPercentageSvg} {...props} />
)
export const EasyFix = (props: IconProps) => (
  <Component component={EasyFixSVG} {...props} />
)
export const EasyFixCyan = (props: IconProps) => (
  <Component component={EasyFixCyanSVG} {...props} />
)
export const EasyFixOrange = (props: IconProps) => (
  <Component component={EasyFixOrangeSVG} {...props} />
)
export const UltimateFix = (props: IconProps) => (
  <Component component={UltimateFixSVG} {...props} />
)
export const Ingress = (props: IconProps) => (
  <Component component={IngressSvg} {...props} />
)
export const Exploite = (props: IconProps) => (
  <Component component={ExploiteSvg} {...props} />
)
export const PublicImage = (props: IconProps) => (
  <Component component={PublicImageSVG} {...props} />
)
export const Runtime = (props: IconProps) => (
  <Component component={RuntimeSvg} {...props} />
)
export const Bitbucket = (props: IconProps) => (
  <Component component={BitbucketSVG} {...props} />
)
export const GitHub = (props: IconProps) => (
  <Component component={GitHubSVG} {...props} />
)
export const VSCode = (props: IconProps) => (
  <Component component={VSCodeSVG} {...props} />
)
export const OnPremCodeRepo = (props: IconProps) => (
  <Component component={OnPremRepoSvg} {...props} />
)
export const LocalImage = (props: IconProps) => (
  <Component component={OnPremImageSvg} {...props} />
)
export const Snyk = (props: IconProps) => (
  <Component component={SnykSvg} {...props} />
)
export const Checkmarx = (props: IconProps) => (
  <Component component={CheckmarxSvg} {...props} />
)
export const Jfrog = (props: IconProps) => (
  <Component component={JfrogSvg} {...props} />
)
export const Amazon = (props: IconProps) => (
  <Component component={AmazonSVG} {...props} />
)
export const Asana = (props: IconProps) => (
  <Component component={AsanaSVG} {...props} />
)
export const AzureRepos = (props: IconProps) => (
  <Component component={AzureReposSVG} {...props} />
)
export const JiraSoftwareLogo = (props: IconProps) => (
  <Component component={JiraSoftwareLogoSVG} {...props} />
)
export const JiraSoftwareLogoOutlined = (props: IconProps) => (
  <Component component={JiraSoftwareLogoOutlinedSVG} {...props} />
)
export const Slack = (props: IconProps) => (
  <Component component={SlackSVG} {...props} />
)
export const Wiz = (props: IconProps) => (
  <Component component={WizSvg} {...props} />
)
export const Qualys = (props: IconProps) => (
  <Component component={QualysSvg} {...props} />
)
export const Orca = (props: IconProps) => (
  <Component component={OrcaSvg} {...props} />
)
export const PrismaCloud = (props: IconProps) => (
  <Component component={PrismaCloudSvg} {...props} />
)
export const Mend = (props: IconProps) => (
  <Component component={MendSvg} {...props} />
)
export const Gitlab = (props: IconProps) => (
  <Component component={GitlabSvg} {...props} />
)
export const GitlabDark = (props: IconProps) => (
  <Component component={GitlabDarkSvg} {...props} />
)
export const ServiceNow = (props: IconProps) => (
  <Component component={ServiceNowSvg} {...props} />
)
export const Splunk = (props: IconProps) => (
  <Component component={SplunkSvg} {...props} />
)
export const ExternalLinkOutlined = (props: IconProps) => (
  <Component component={ExternalLinkOutlinedSvg} {...props} />
)
export const StoryJira = (props: IconProps) => (
  <Component component={StoryJiraSvg} {...props} />
)
export const JiraLogo = (props: IconProps) => (
  <Component component={JiraLogoSVG} {...props} />
)
export const DockerHub = (props: IconProps) => (
  <Component component={DockerHubSvg} {...props} />
)
export const EnterKey = (props: IconProps) => (
  <Component component={EnterKeySvg} {...props} />
)
export const SpaceKey = (props: IconProps) => (
  <Component component={SpaceKeySvg} {...props} />
)
export const KodemLogo = (props: IconProps) => (
  <Component component={KodemLogoSvg} {...props} />
)
export const Python = (props: IconProps) => (
  <Component component={PythonDarkSVG} {...props} />
)
export const Javascript = (props: IconProps) => (
  <Component component={JavascriptDarkSVG} {...props} />
)
export const Java = (props: IconProps) => (
  <Component component={JavaDarkSVG} {...props} />
)
export const Kotlin = (props: IconProps) => (
  <Component component={KotlinDarkSvg} {...props} />
)
export const Go = (props: IconProps) => (
  <Component component={GoDarkSVG} {...props} />
)
export const Debian = (props: IconProps) => (
  <Component component={DebianDarkSVG} {...props} />
)
export const Apk = (props: IconProps) => (
  <Component component={ApkDarkSvg} {...props} />
)
export const Binary = (props: IconProps) => (
  <Component component={BinaryDarkSvg} {...props} />
)
export const Lua = (props: IconProps) => (
  <Component component={LuaDarkSvg} {...props} />
)
export const Dart = (props: IconProps) => (
  <Component component={DartDarkSvg} {...props} />
)
export const R = (props: IconProps) => (
  <Component component={RDarkSvg} {...props} />
)
export const Ruby = (props: IconProps) => (
  <Component component={RubyDarkSVG} {...props} />
)
export const Rpm = (props: IconProps) => (
  <Component component={RpmDarkSVG} {...props} />
)
export const Scala = (props: IconProps) => (
  <Component component={ScalaDarkSVG} {...props} />
)
export const Php = (props: IconProps) => (
  <Component component={PhpDarkSVG} {...props} />
)
export const Net = (props: IconProps) => (
  <Component component={NetDarkSvg} {...props} />
)
export const UnknownLanguage = (props: IconProps) => (
  <Component component={UnknownLanguageSvg} {...props} />
)
export const Fix = (props: IconProps) => (
  <Component component={FixSvg} {...props} />
)
export const HasFix = (props: IconProps) => (
  <Component component={HasFixSvg} {...props} />
)
export const Table = (props: IconProps) => (
  <Component component={TableSvg} {...props} />
)
export const TrashOutlined = (props: IconProps) => (
  <Component component={TrashOutlinedSvg} {...props} />
)
export const BugDemo = (props: IconProps) => (
  <Component component={BugDemoSVG} {...props} />
)
export const PackageDemo = (props: IconProps) => (
  <Component component={PackageDemoSVG} {...props} />
)
export const Image = (props: IconProps) => (
  <Component component={ImageSVG} {...props} />
)
export const ImageDemo = (props: IconProps) => (
  <Component component={ImageDemoSVG} {...props} />
)
export const BitbucketDark = (props: IconProps) => (
  <Component component={BitbucketDarkSvg} {...props} />
)
export const LinkExpired = (props: IconProps) => (
  <Component component={LinkExpiredSvg} {...props} />
)
export const GCR = (props: IconProps) => (
  <Component component={GcrSVG} {...props} />
)
export const Remediator = (props: IconProps) => (
  <Component component={RemediatorSVG} {...props} />
)
export const ExploitPOC = (props: IconProps) => (
  <Component component={ExploitPOCSvg} {...props} />
)
export const Hierarchy = (props: IconProps) => (
  <Component component={HierarchySvg} {...props} />
)
export const TagMore = (props: IconProps) => (
  <Component component={TagMoreSvg} {...props} />
)
export const EmptyLabels = (props: IconProps) => (
  <Component component={EmptyLabelsSvg} {...props} />
)
export const SevereIssue = (props: IconProps) => (
  <Component component={SevereIssueSvg} {...props} />
)

export const Kubernetes = ({ className, ...props }: IconProps) => (
  <Component
    component={KubernetesSvg}
    className={cn(styles.kubernetes, className)}
    {...props}
  />
)
export const ECS = ({ className, ...props }: IconProps) => (
  <Component
    component={ECSSvg}
    className={cn(styles.ecs, className)}
    {...props}
  />
)
export const Fargate = ({ className, ...props }: IconProps) => (
  <Component
    component={FargateSvg}
    className={cn(styles.ecs, className)}
    {...props}
  />
)
export const EC2 = ({ className, ...props }: IconProps) => (
  <Component
    component={EC2Svg}
    className={cn(styles.ecs, className)}
    {...props}
  />
)
export const VM = ({ className, ...props }: IconProps) => (
  <Component
    component={VMSvg}
    className={cn(styles.vm, className)}
    {...props}
  />
)

export const CloudSync = (props: IconProps) => (
  <Component component={CloudSyncSvg} {...props} />
)
export const ApplicationExpand = (props: IconProps) => (
  <Component component={ApplicationExpandSvg} {...props} />
)
export const ArrowUp = (props: IconProps) => (
  <Component component={ArrowUpSVG} {...props} />
)
export const FlatListIcon = (props: IconProps) => (
  <Component component={FlatListSvg} {...props} />
)
export const Package = (props: IconProps) => (
  <Component component={PackageSvg} {...props} />
)
export const InternetFacing = (props: IconProps) => (
  <Component component={InternetFacingSvg} {...props} />
)
export const InternalApp = (props: IconProps) => (
  <Component component={InternalAppSvg} {...props} />
)

export const NoFound = (props: IconProps) => (
  <Component component={NoFoundSvg} {...props} />
)
export const NoScope = (props: IconProps) => (
  <Component component={NoScopeSvg} {...props} />
)
export const Mal = (props: IconProps) => (
  <Component component={MalSvg} {...props} />
)

export const KeyIcon = (props: IconProps) => (
  <Component component={KeyIconSvg} {...props} />
)

export const HierarchyLineIcon = (props: IconProps) => (
  <Component component={HierarchyLineIconSvg} {...props} />
)

export const CodeWeakness = (props: IconProps) => (
  <Component component={CodeWeaknessSvg} {...props} />
)

export const ExposedSecret = (props: IconProps) => (
  <Component component={ExposedSecretSvg} {...props} />
)

export const TypeScript = (props: IconProps) => (
  <Component component={TypeScriptDarkSVG} {...props} />
)

export const Rust = (props: IconProps) => (
  <Component component={RustDarkSVG} {...props} />
)

export const Swift = (props: IconProps) => (
  <Component component={SwiftDarkSVG} {...props} />
)

export const OpenSourceIssue = (props: IconProps) => (
  <Component component={OpenSourceIssueSvg} {...props} />
)

export const CodeIssues = (props: IconProps) => (
  <Component component={CodeIssuesSvg} {...props} />
)

export const C = (props: IconProps) => (
  <Component component={CDarkSvg} {...props} />
)

export const CSharp = (props: IconProps) => (
  <Component component={CSharpDarkSvg} {...props} />
)

export const IconWithFrame = (
  Icon: React.FC<IconProps>,
  {
    className,
    size,
    background = true,
    ...props
  }: IconProps & { background?: boolean; size?: "small" | "large" | "xl" } = {}
) => (
  <Icon
    className={cn(
      styles.withFrame,
      background && styles.withBackground,
      !background && styles.withoutBackground,
      styles.lgFrame,
      size === "small" && styles.smFrame,
      size === "xl" && styles.xlFrame,
      className
    )}
    {...props}
  />
)

export const ConnectPlug = (props: IconProps) => (
  <Component component={ConnectPlugSvg} {...props} />
)

export const ConnectEnvData = (props: IconProps) => (
  <Component component={ConnectEnvDataSvg} {...props} />
)

export const KodemCI = (props: IconProps) => (
  <Component component={KodemCISvg} {...props} />
)

export const Jenkins = (props: IconProps) => (
  <Component component={JenkinsSvg} {...props} />
)

export const CircleCI = (props: IconProps) => (
  <Component component={CircleCISvg} {...props} />
)

export const GithubActions = (props: IconProps) => (
  <Component component={GithubActionsSvg} {...props} />
)

export const Injection = (props: IconProps) => (
  <Component component={InjectionSvg} {...props} />
)

export const GKE = (props: IconProps) => (
  <Component component={GKESvg} {...props} />
)

export const AKS = (props: IconProps) => (
  <Component component={AKSSvg} {...props} />
)

export const EKS = (props: IconProps) => (
  <Component component={EKSSvg} {...props} />
)

export const Webhook = (props: IconProps) => (
  <Component component={WebhookSvg} {...props} />
)

export const Zombie = (props: IconProps) => (
  <Component component={ZombieSvg} {...props} />
)

export const AttackScenarios = (props: IconProps) => (
  <Component component={AttackScenariosSvg} {...props} />
)

export const Ai = (props: IconProps) => (
  <Component component={AiSvg} {...props} />
)

export const DeploymentTypeIconsGroup = {
  EC2: EC2,
  Kubernetes: Kubernetes,
  ECS: ECS,
  VM: VM,
  unknown: null
}

export const LanguageIconsGroup = {
  python: Python,
  javascript: Javascript,
  go: Go,
  java: Java,
  ruby: Ruby,
  scala: Scala,
  php: Php,
  rpm: Rpm,
  deb: Debian,
  debian: Debian,
  apk: Apk,
  dotnet: Net,
  typescript: TypeScript,
  c: C,
  "c++": C,
  "c#": CSharp,
  R: R,
  rust: Rust,
  swift: Swift,
  kotlin: Kotlin,
  binary: Binary,
  dart: Dart,
  lua: Lua,
  unknown: UnknownLanguage
}

type ExploitIconsGroupType = {
  IN_THE_WILD: React.FC<IconProps>
  POC: React.FC<IconProps>
  UNDETERMINED: null
}

export const ExploitIconsGroup: ExploitIconsGroupType = {
  IN_THE_WILD: Exploite,
  POC: ExploitPOC,
  UNDETERMINED: null
}
